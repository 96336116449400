
import {defineComponent, ref, createVNode, UnwrapRef, reactive, onBeforeMount, nextTick,onMounted} from "vue";
import {
  PlusOutlined,
  ExclamationCircleOutlined,
  SaveOutlined,
  DownloadOutlined,
} from '@ant-design/icons-vue';
import {Modal, message} from 'ant-design-vue';
import {
  getX2ServerList,
  addX2Server,
  updateX2Server,
  deleteX2Server,
  setDefaultServer,
  getX2serverInfo
} from "@/api/api";
import {AutoSaveConfig, getConfigData, GetSaveState, sysSaveConfig} from "@/api/api_x2server";

interface FormState {
  Id: string | undefined;
  Name: string;
  Host: string;
  Token: string;
}

export default defineComponent({
  components: {
    PlusOutlined,
    SaveOutlined,
    DownloadOutlined,
  },
  setup() {
    const formRef = ref();
    const serviceList = ref<Array<FormState>>()
    const currentId = ref<number>();
    const modalTitle = ref<string>("");
    const visible = ref<boolean>(false);
    const spinning = ref<boolean>(false);
    const isAuto = ref<boolean>(false);
    const tip = ref<string>("");
    const confirmLoading = ref<boolean>(false);
    const formState: UnwrapRef<FormState> = reactive({
      Id: undefined,
      Name: '',
      Host: '',
      Token: '',
    });
    const rules = {
      Name: [
        {required: true, message: '请输入服务名', trigger: 'blur'},
      ],
      Host: [
        {required: true, message: '请输入Host地址', trigger: 'blur'},
      ],
      Token: [
        {required: true, message: '请输入token', trigger: 'blur'},
      ],
    }
    onMounted(async ()=>{
      init()
    })
    const init = async() => {
      const res: any = await GetSaveState();
      isAuto.value = res.Data
    }

    const changeService = async (id: number) => {
      const res: any = await setDefaultServer({
        id: sessionStorage.getItem("userId"),
        x2ServerId: id
      })
      if (res.Result.toUpperCase() == "OK") {
        message.success("设置成功！")
        currentId.value = id
        sessionStorage.setItem("X2ServerID",id+"");
        const res2: any = await getX2serverInfo({id})
        sessionStorage.setItem("Token", res2.Token)
        sessionStorage.setItem("Host", res2.Host)
        init()
      }
    }

    const toAddService = () => {
      modalTitle.value = "添加服务"
      formState.Host = "http://"
      formState.Token = "A45EA830FD490D0C0890791DF44E7E5F"
      visible.value = true;
    };
    const toEditService = (data: FormState) => {
      modalTitle.value = "修改服务"
      visible.value = true;
      nextTick(() => {
        Object.assign(formState, data)
      })
    };

    const handleOk = () => {
      formRef.value
          .validate()
          .then(async () => {
            confirmLoading.value = true;
            let res: any;
            if (formState.Id) {
              res = await updateX2Server(formState)
            } else {
              res = await addX2Server(formState)
            }
            confirmLoading.value = false;
            if (res.Result.toUpperCase() == "OK") {
              if (formState.Id) {
                if(currentId.value + "" == formState.Id){
                  sessionStorage.setItem("X2ServerID",formState.Id);
                  sessionStorage.setItem("Token", formState.Token)
                  sessionStorage.setItem("Host", formState.Host)
                }
                message.success("修改成功！")
              } else {
                message.success("添加成功！")
              }
              getList();
              closeModal();
            }
          });
    };
    const closeModal = () => {
      formState.Id = undefined
      formRef.value.resetFields()
      visible.value = false;
    };

    const deleteService = (id: number) => {
      Modal.confirm({
        title: () => '是否删除该服务?',
        icon: () => createVNode(ExclamationCircleOutlined),
        okText: () => '是',
        okType: 'danger',
        cancelText: () => '否',
        async onOk() {
          const res: any = await deleteX2Server({id})
          if (res.Result.toUpperCase() == "OK") {
            message.success("删除成功！")
            getList();
          }
        },
      });
    }

    /**
     * 获取列表
     */
    const getList = async () => {
      const res: any = await getX2ServerList({page: 1, count: 9999})
      serviceList.value = res.Items;
    };

    onBeforeMount(() => {
      getList()
      const X2ServerID = sessionStorage.getItem("X2ServerID")
      if(X2ServerID){
        currentId.value = Number(X2ServerID)
      }
    })

    const download = async () => {
      spinning.value = true
      tip.value = "下载中..."
      const res: any = await getConfigData();
      let url = window.URL.createObjectURL(new Blob([res]))
      let a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.setAttribute('download',"AppData.xml")
      document.body.appendChild(a)
      a.click() //执行下载
      window.URL.revokeObjectURL(a.href) //释放url
      document.body.removeChild(a)
      spinning.value = false
    }
    const saveConfig = async () => {
      spinning.value = true
      tip.value = "保存中..."
      const res: any = await sysSaveConfig();
      if (res.Result.toUpperCase() == "OK") {
        message.success("保存成功！")
      }
      spinning.value = false
    }
    const autoChange = async (e: any) => {
      const res: any = await AutoSaveConfig({state:e?1:0});
      if (res.Result.toUpperCase() == "OK") {
        message.success(e?"已启用自动保存":"已关闭自动保存")
      }
    }

    return {
      formRef,
      spinning,
      tip,
      serviceList,
      currentId,
      visible,
      modalTitle,
      confirmLoading,
      formState,
      rules,
      isAuto,
      changeService,
      deleteService,
      toAddService,
      toEditService,
      handleOk,
      closeModal,
      download,
      saveConfig,
      autoChange,
    }
  }
})
