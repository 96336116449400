import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c2e9c95c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  style: {"position":"relative","padding":"5px"},
  class: "autohide"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DownloadOutlined = _resolveComponent("DownloadOutlined")!
  const _component_SaveOutlined = _resolveComponent("SaveOutlined")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_PlusOutlined = _resolveComponent("PlusOutlined")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_spin, {
      spinning: _ctx.spinning,
      tip: _ctx.tip
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_row, { style: {"text-align":"center"} }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serviceList, (data) => {
              return (_openBlock(), _createBlock(_component_a_col, {
                span: 4,
                key: data.Id,
                class: "serviceBox"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("a", {
                    class: _normalizeClass(["boxNameSpace", {'currentBox':data.Id === _ctx.currentId}]),
                    onClick: ($event: any) => (_ctx.toEditService(data))
                  }, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("a", {
                        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.download && _ctx.download(...args)), ["stop"])),
                        title: "下载配置文件"
                      }, [
                        _createVNode(_component_DownloadOutlined, {
                          class: "biaoji",
                          style: {"left":"20%"}
                        })
                      ]),
                      _createElementVNode("a", {
                        onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.saveConfig && _ctx.saveConfig(...args)), ["stop"])),
                        title: "保存配置"
                      }, [
                        _createVNode(_component_SaveOutlined, {
                          class: "biaoji",
                          style: {"left":"40%"}
                        })
                      ]),
                      _createElementVNode("a", {
                        onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"])),
                        title: "自动保存"
                      }, [
                        _createVNode(_component_a_switch, {
                          onChange: _ctx.autoChange,
                          checked: _ctx.isAuto,
                          "onUpdate:checked": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isAuto) = $event)),
                          class: "biaoji",
                          style: {"left":"60%"}
                        }, null, 8, ["onChange", "checked"])
                      ]),
                      _createTextVNode(" " + _toDisplayString(data.Name), 1)
                    ])
                  ], 10, _hoisted_1),
                  _createElementVNode("div", null, [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      style: {"float":"left"},
                      onClick: ($event: any) => (_ctx.changeService(data.Id))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("切换")
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_a_button, {
                      type: "primary",
                      danger: "",
                      style: {"float":"right"},
                      onClick: ($event: any) => (_ctx.deleteService(data.Id))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("删除")
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ])
                ]),
                _: 2
              }, 1024))
            }), 128)),
            _createVNode(_component_a_col, {
              span: 4,
              class: "serviceBox addBox"
            }, {
              default: _withCtx(() => [
                _createElementVNode("a", {
                  class: "boxNameSpace",
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toAddService && _ctx.toAddService(...args)))
                }, [
                  _createVNode(_component_PlusOutlined),
                  _createTextVNode(" 添加 ")
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_modal, {
              title: _ctx.modalTitle,
              visible: _ctx.visible,
              "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.visible) = $event)),
              "confirm-loading": _ctx.confirmLoading,
              maskClosable: false,
              onOk: _ctx.handleOk,
              onCancel: _ctx.closeModal
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form, {
                  ref: "formRef",
                  model: _ctx.formState,
                  rules: _ctx.rules,
                  "label-col": { span: 4 }
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, {
                      label: "服务名",
                      name: "Name"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.formState.Name,
                          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formState.Name) = $event)),
                          autocomplete: "off"
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_form_item, {
                      label: "Host",
                      name: "Host"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.formState.Host,
                          "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formState.Host) = $event)),
                          autocomplete: "off"
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_form_item, {
                      label: "Token",
                      name: "Token"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.formState.Token,
                          "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formState.Token) = $event)),
                          autocomplete: "off"
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["model", "rules"])
              ]),
              _: 1
            }, 8, ["title", "visible", "confirm-loading", "onOk", "onCancel"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["spinning", "tip"])
  ]))
}